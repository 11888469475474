import { useUserStore } from "@/stores/user";
import { useNuxtApp } from "nuxt/app";
import { useAccessToken } from "~/composables/useAccessToken";

export function useAccount() {

  const {getAccessToken} = useAccessToken();

  const { getAccount, updateAccountStore} = useUserStore();

  const createAccount = async (params: AccountCreationFormFields) => {
    try {
      const { $api } = useNuxtApp();
      const token = await getAccessToken();

      const response = await $api.post('/accounts', {
        first_name: params.first_name,
        last_name: params.last_name,
        date_of_birth: params.date_of_birth,
        onboarding_step: 4
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      return response.data;

    } catch (error) {
      throw error;
    }
  }

  const updateAccount = async (params = {}) => {
    try {
      const { $api } = useNuxtApp();
      const token = await getAccessToken();

      const response = await $api.post('/accounts/' + getAccount.id, {
        _method: 'PUT',
        ...params
      }, {
        headers: {
          'Content-Type': 'multipart/form-data', // Important for file uploads,
          Authorization: `Bearer ${token}`
        },
      });

      if (response.data.account) {
        updateAccountStore(response.data.account)
      }

      return response.data;

    } catch (error) {
      throw error;
    }
  }

  return {
    createAccount,
    getAccount,
    updateAccount
  }
}
